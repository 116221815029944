import React, { useEffect, useState } from 'react';
import { 
  Table, 
  Button, 
  Modal, 
  Input, 
  message, 
  Space,
  Card,
  InputNumber,
  Typography
} from 'antd';
import { 
  PlusOutlined, 
  DeleteOutlined, 
  EditOutlined,
  SearchOutlined,
  DollarOutlined
} from '@ant-design/icons';
import { getPrices } from '../services/api';
import { getAdminPassword } from '../utils/auth';

const { Text } = Typography;

const Prices = () => {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editingPrice, setEditingPrice] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [newPriceData, setNewPriceData] = useState({
    title: '',
    value: 0
  });

  const loadPrices = async () => {
    try {
      setLoading(true);
      const password = getAdminPassword();
      const response = await getPrices(password);
      
      if (response.status) {
        setPrices(response.prices);
      } else {
        message.error(response.errMsg || 'Ошибка загрузки данных');
      }
    } catch (error) {
      console.error('Error loading prices:', error);
      message.error('Ошибка при загрузке данных');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPrices();
  }, []);

  const handleAdd = async () => {
    if (!newPriceData.title.trim()) {
      message.error('Введите название');
      return;
    }

    try {
      const newPrice = {
        id: Date.now().toString(),
        title: newPriceData.title.trim(),
        value: Number(newPriceData.value)
      };

      setPrices(prev => [...prev, newPrice]);
      setNewPriceData({ title: '', value: 0 });
      setIsModalVisible(false);
      message.success('Цена добавлена');
    } catch (error) {
      message.error('Ошибка при добавлении');
    }
  };

  const handleEdit = async () => {
    if (!editingPrice) return;

    try {
      setPrices(prev => prev.map(price => 
        price.id === editingPrice.id ? editingPrice : price
      ));
      setEditModalVisible(false);
      setEditingPrice(null);
      message.success('Цена обновлена');
    } catch (error) {
      message.error('Ошибка при обновлении');
    }
  };

  const handleDelete = async (id) => {
    try {
      setPrices(prev => prev.filter(price => price.id !== id));
      message.success('Цена удалена');
    } catch (error) {
      message.error('Ошибка при удалении');
    }
  };

  const filteredPrices = prices.filter(price => 
    price.title.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      title: 'НАЗВАНИЕ',
      dataIndex: 'title',
      key: 'title',
      render: (text) => (
        <Text style={{ 
          fontSize: '14px',
          color: '#fff',
          fontWeight: 500 
        }}>
          {text}
        </Text>
      )
    },
    {
      title: 'ЦЕНА',
      dataIndex: 'value',
      key: 'value',
      width: 100,
      render: (value) => (
        <Text style={{ 
          fontSize: '14px',
          color: '#1668dc',
          fontWeight: 500 
        }}>
          ${value}
        </Text>
      )
    },
    {
      title: 'ДЕЙСТВИЯ',
      key: 'actions',
      width: 90,
      align: 'right',
      render: (_, record) => (
        <Space size={4}>
          <Button
            type="text"
            icon={<EditOutlined style={{ color: '#1668dc' }} />}
            onClick={() => {
              setEditingPrice(record);
              setEditModalVisible(true);
            }}
            style={{ padding: '4px' }}
          />
          <Button
            type="text"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
            style={{ padding: '4px' }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className="p-3 min-h-screen bg-[#141414]">
      <Card
        className="w-full bg-[#1f1f1f] border-[#434343]"
        bodyStyle={{ 
          padding: '12px',
        }}
      >
        <div className="mb-4">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsModalVisible(true)}
            className="w-full h-10 bg-[#1668dc] hover:bg-blue-600"
          >
            Добавить цену
          </Button>
        </div>

        <Input
          placeholder="Поиск"
          prefix={<SearchOutlined className="text-[#1668dc]" />}
          allowClear
          onChange={e => setSearchText(e.target.value)}
          className="mb-4"
          style={{
            background: '#141414',
            borderColor: '#434343',
            color: '#fff'
          }}
        />

        <Table
          dataSource={filteredPrices}
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
            showQuickJumper: false,
            showTotal: (total) => <span style={{ color: '#a6a6a6' }}>{`Всего ${total} записей`}</span>
          }}
          className="custom-table"
          size="middle"
        />
      </Card>

      {/* Модальное окно добавления */}
      <Modal
        title="Добавить цену"
        open={isModalVisible}
        onOk={handleAdd}
        onCancel={() => {
          setIsModalVisible(false);
          setNewPriceData({ title: '', value: 0 });
        }}
        okText="Добавить"
        cancelText="Отмена"
      >
        <div style={{ marginTop: 16 }}>
          <Input
            placeholder="Название"
            prefix={<DollarOutlined />}
            value={newPriceData.title}
            onChange={e => setNewPriceData(prev => ({ ...prev, title: e.target.value }))}
            style={{ marginBottom: 16 }}
          />
          <InputNumber
            placeholder="Цена"
            min={0}
            value={newPriceData.value}
            onChange={value => setNewPriceData(prev => ({ ...prev, value }))}
            style={{ width: '100%' }}
            prefix="$"
          />
        </div>
      </Modal>

      {/* Модальное окно редактирования */}
      <Modal
        title="Редактировать цену"
        open={editModalVisible}
        onOk={handleEdit}
        onCancel={() => {
          setEditModalVisible(false);
          setEditingPrice(null);
        }}
        okText="Сохранить"
        cancelText="Отмена"
      >
        <div style={{ marginTop: 16 }}>
          <Input
            placeholder="Название"
            prefix={<DollarOutlined />}
            value={editingPrice?.title}
            onChange={e => setEditingPrice(prev => ({ ...prev, title: e.target.value }))}
            style={{ marginBottom: 16 }}
          />
          <InputNumber
            placeholder="Цена"
            min={0}
            value={editingPrice?.value}
            onChange={value => setEditingPrice(prev => ({ ...prev, value }))}
            style={{ width: '100%' }}
            prefix="$"
          />
        </div>
      </Modal>

      <style jsx global>{`
        .ant-card {
          background: #1f1f1f !important;
        }
        
        .ant-table {
          background: transparent !important;
          color: white !important;
        }
        
        .ant-table-thead > tr > th {
          background: #1f1f1f !important;
          color: #a6a6a6 !important;
          border-bottom: 1px solid #434343 !important;
          padding: 12px 8px !important;
          font-size: 12px !important;
        }
        
        .ant-table-tbody > tr > td {
          background: transparent !important;
          border-bottom: 1px solid #434343 !important;
          padding: 12px 8px !important;
        }

        .ant-table-tbody > tr:hover > td {
          background: #262626 !important;
        }

        .ant-input {
          background: #141414 !important;
          border-color: #434343 !important;
          color: white !important;
        }

        .ant-input:hover, .ant-input:focus {
          border-color: #1668dc !important;
        }

        .ant-input-number {
          background: #141414 !important;
          border-color: #434343 !important;
          color: white !important;
        }

        .ant-input-number:hover {
          border-color: #1668dc !important;
        }

        .ant-input-number-input {
          color: white !important;
        }

        .ant-modal-content,
        .ant-modal-header {
          background: #1f1f1f !important;
          border-bottom: 1px solid #434343 !important;
        }

        .ant-modal-title {
          color: white !important;
        }

        .ant-modal-close-x {
          color: #a6a6a6 !important;
        }

        .ant-pagination-item {
          background: #1f1f1f !important;
          border-color: #434343 !important;
        }

        .ant-pagination-item a {
          color: white !important;
        }

        .ant-pagination-item-active {
          border-color: #1668dc !important;
        }

        .ant-pagination-prev button,
        .ant-pagination-next button {
          background: #1f1f1f !important;
          border-color: #434343 !important;
          color: white !important;
        }

        @media (max-width: 768px) {
          .ant-table {
            font-size: 14px;
          }
          
          .ant-table-thead > tr > th,
          .ant-table-tbody > tr > td {
            padding: 8px 4px !important;
            white-space: nowrap;
          }
          
          .ant-btn {
            padding: 4px 8px;
          }
        }
      `}</style>
    </div>
  );
};

export default Prices;