import React, { useEffect, useState, useMemo } from 'react';
import { Table, Spin, message, Input, Switch } from 'antd';
import { getAdminPassword } from '../utils/auth';
import { getUsers, setUserDeactivate, setUserCoin } from '../services/api';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';

const { Search } = Input;

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [pageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const loadUsers = async () => {
    const password = getAdminPassword();
    try {
      const res = await getUsers(password);
      if (res.status) {
        setUsers(res.users.map(user => ({
          ...user,
          coin: Number(user.coin).toFixed(2)
        })));
      } else {
        message.error(res.errMsg || 'Не удалось загрузить пользователей');
      }
    } catch (err) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const handleDeactivate = async (record) => {
    try {
      const password = getAdminPassword();
      const res = await setUserDeactivate(password, record.id, !record.deactivate);
      if (res.status) {
        message.success('Статус пользователя изменен');
        setUsers(prevUsers => 
          prevUsers.map(user => 
            user.id === record.id 
              ? { ...user, deactivate: !user.deactivate }
              : user
          )
        );
      } else {
        message.error(res.errMsg || 'Ошибка изменения статуса');
      }
    } catch (err) {
      message.error('Ошибка при изменении статуса');
    }
  };

  const handleCoinChange = debounce(async (record, value) => {
    try {
      const newCoin = parseFloat(value);
      if (isNaN(newCoin)) return;

      const password = getAdminPassword();
      const res = await setUserCoin(password, record.id, newCoin);
      if (res.status) {
        message.success('Баланс обновлен');
        setUsers(prevUsers => 
          prevUsers.map(user => 
            user.id === record.id 
              ? { ...user, coin: Number(newCoin).toFixed(2) }
              : user
          )
        );
      } else {
        message.error(res.errMsg || 'Ошибка обновления баланса');
      }
    } catch (err) {
      message.error('Ошибка при обновлении баланса');
    }
  }, 500);

  const filteredUsers = useMemo(() => {
    if (!searchText) return users;
    const searchLower = searchText.toLowerCase();
    return users.filter(user => 
      user.id.toLowerCase().includes(searchLower) || 
      `${user.username}`.toLowerCase().includes(searchLower)
    );
  }, [users, searchText]);

  const columns = [
    {
      title: 'ПОЛЬЗОВАТЕЛЬ',
      dataIndex: 'username',
      key: 'username',
      render: (text) => (
        <div className="text-base text-white font-medium">{text}</div>
      )
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      render: (text) => (
        <div className="text-sm text-gray-400">{text}</div>
      )
    },
    {
      title: 'БАЛАНС',
      dataIndex: 'coin',
      key: 'coin',
      render: (coin, record) => (
        <Input
          prefix="$"
          type="number"
          defaultValue={coin}
          onBlur={(e) => handleCoinChange(record, e.target.value)}
          className="w-24 bg-[#1f1f1f] border-[#434343] text-white"
        />
      )
    },
    {
      title: 'СТАТУС',
      key: 'status',
      render: (_, record) => (
        <Switch
          checked={!record.deactivate}
          onChange={() => handleDeactivate(record)}
          checkedChildren="Активен"
          unCheckedChildren="Неактивен"
          className="min-w-[80px]"
        />
      )
    }
  ];

  return (
    <div className="p-4">
      <div className="mb-4">
        <Search
          placeholder="Поиск по ID или имени"
          allowClear
          enterButton={<SearchOutlined />}
          onChange={(e) => setSearchText(e.target.value)}
          className="max-w-md"
          style={{
            background: '#1f1f1f',
            borderColor: '#434343'
          }}
        />
      </div>

      {loading ? (
        <div className="flex justify-center my-8">
          <Spin size="large" />
        </div>
      ) : (
        <div className="overflow-x-auto">
          <Table 
            dataSource={filteredUsers}
            columns={columns}
            rowKey="id"
            pagination={{
              total: filteredUsers.length,
              pageSize,
              current: currentPage,
              onChange: setCurrentPage,
              showSizeChanger: false,
              className: "bg-[#1f1f1f]"
            }}
            className="custom-table"
          />
        </div>
      )}
      
      <style jsx global>{`
        .ant-table {
          background: transparent !important;
          color: white !important;
        }
        
        .ant-table-thead > tr > th {
          background: #1f1f1f !important;
          color: #a6a6a6 !important;
          border-bottom: 1px solid #434343 !important;
          padding: 12px 8px !important;
          font-size: 12px !important;
        }
        
        .ant-table-tbody > tr > td {
          background: transparent !important;
          border-bottom: 1px solid #434343 !important;
          padding: 12px 8px !important;
        }

        .ant-table-tbody > tr:hover > td {
          background: #1f1f1f !important;
        }

        .ant-input {
          background: #1f1f1f !important;
          border-color: #434343 !important;
          color: white !important;
        }

        .ant-input:hover, .ant-input:focus {
          border-color: #1668dc !important;
        }

        .ant-switch {
          background: #434343 !important;
        }

        .ant-switch-checked {
          background: #1668dc !important;
        }

        .ant-pagination-item {
          background: #1f1f1f !important;
          border-color: #434343 !important;
        }

        .ant-pagination-item a {
          color: white !important;
        }

        .ant-pagination-item-active {
          border-color: #1668dc !important;
        }

        .ant-pagination-prev button,
        .ant-pagination-next button {
          background: #1f1f1f !important;
          border-color: #434343 !important;
          color: white !important;
        }

        @media (max-width: 768px) {
          .ant-table {
            font-size: 14px;
          }
          
          .ant-table-thead > tr > th,
          .ant-table-tbody > tr > td {
            padding: 8px 4px !important;
          }

          .ant-input-number {
            width: 80px !important;
          }

          .ant-switch {
            min-width: 60px !important;
          }
        }
      `}</style>
    </div>
  );
};

export default Users;