import { useMemo } from 'react';

export const useMetrics = (filteredData) => {
  return useMemo(() => {
    const { stats, users } = filteredData;
    const currentDate = new Date();
    const monthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    
    return {
      totalOrders: stats.length,
      monthlyOrders: stats.filter(order => new Date(order.date) >= monthStart).length,
      totalRevenue: stats.reduce((sum, order) => sum + order.price, 0),
      monthlyRevenue: stats
        .filter(order => new Date(order.date) >= monthStart)
        .reduce((sum, order) => sum + order.price, 0),
      totalUsers: users.length,
      activeUsers: users.filter(user => 
        new Date(user.lastActivity) >= monthStart
      ).length
    };
  }, [filteredData]);
};

export const useGeneralStats = (filteredData) => {
  return useMemo(() => {
    const { stats } = filteredData;

    const topProducts = Object.entries(
      stats.reduce((acc, order) => {
        acc[order.state] = (acc[order.state] || 0) + 1;
        return acc;
      }, {})
    )
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 10);

    const regionDistribution = Object.entries(
      stats.reduce((acc, order) => {
        const region = order.region === 'USA' ? 'USA' : 'World';
        acc[region] = (acc[region] || 0) + 1;
        return acc;
      }, {})
    ).map(([name, value]) => ({ name, value }));

    const worldStats = Object.entries(
      stats
        .filter(order => order.region !== 'USA')
        .reduce((acc, order) => {
          const country = order.state.split('-')[0]?.toUpperCase();
          if (country) acc[country] = (acc[country] || 0) + 1;
          return acc;
        }, {})
    )
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 10);

    return {
      topProducts,
      regionDistribution,
      worldStats
    };
  }, [filteredData]);
};

export const useUsaMetrics = (filteredData) => {
  return useMemo(() => {
    const { stats } = filteredData;
    const usaOrders = stats.filter(order => 
      order.region === 'USA' || 
      order.state.includes('usa-passport') || 
      order.state.includes('usa-ssn')
    );

    const docs = {
      passport: {
        count: usaOrders.filter(order => order.state.includes('passport')).length,
        revenue: usaOrders
          .filter(order => order.state.includes('passport'))
          .reduce((sum, order) => sum + order.price, 0)
      },
      ssn: {
        count: usaOrders.filter(order => order.state.includes('ssn')).length,
        revenue: usaOrders
          .filter(order => order.state.includes('ssn'))
          .reduce((sum, order) => sum + order.price, 0)
      },
      states: {
        count: usaOrders.filter(order => 
          !order.state.includes('passport') && 
          !order.state.includes('ssn')
        ).length,
        revenue: usaOrders
          .filter(order => !order.state.includes('passport') && !order.state.includes('ssn'))
          .reduce((sum, order) => sum + order.price, 0)
      }
    };

    const statesTop = Object.entries(
      usaOrders
        .filter(order => !order.state.includes('passport') && !order.state.includes('ssn'))
        .reduce((acc, order) => {
          const state = order.state.split('-')[1]?.toUpperCase();
          if (state) acc[state] = (acc[state] || 0) + 1;
          return acc;
        }, {})
    )
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 10);

    return { docs, statesTop };
  }, [filteredData]);
};