import React, { useEffect, useState, useMemo } from 'react';
import { 
  Card, 
  Row, 
  Col, 
  Spin, 
  message, 
  Statistic, 
  Select, 
  DatePicker, 
  Switch, 
  Input, 
  Button, 
  Modal, 
  Table,
  Space
} from 'antd';
import { getStatistics, getUsers } from '../services/api';
import { getAdminPassword } from '../utils/auth';
import { excludedAccounts } from '../config/excludedAccounts';
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer,
  CartesianGrid, Legend, LineChart, Line, PieChart, Pie, Cell
} from 'recharts';
import {
  DollarOutlined, UserOutlined, ShoppingCartOutlined,
  TeamOutlined, SettingOutlined, RiseOutlined
} from '@ant-design/icons';

import { DashboardCharts } from './DashboardCharts';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

const Dashboard = () => {
  // States
  const [data, setData] = useState({ statistics: [], users: [] });
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState([]);
  const [region, setRegion] = useState('all');
  const [excludeSystemUsers, setExcludeSystemUsers] = useState(true);
  const [excludedUsers, setExcludedUsers] = useState([...excludedAccounts]);
  const [isExcludeModalVisible, setIsExcludeModalVisible] = useState(false);
  const [searchText, setSearchText] = useState('');

  // Data Loading
  useEffect(() => {
    const loadData = async () => {
      try {
        const password = getAdminPassword();
          const statsRes = await getStatistics(password);
          console.log(statsRes)
          const usersRes = await getUsers(password);
          console.log(usersRes)

        if (statsRes.status && usersRes.status) {
          setData({
            statistics: statsRes.orders,
            users: usersRes.users
          });
        } else {
          message.error('Ошибка загрузки данных');
        }
      } catch (error) {
        message.error('Ошибка при загрузке данных');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  // User Exclusion Management
  const handleExcludeUser = (username) => {
    if (!excludedUsers.includes(username)) {
      setExcludedUsers([...excludedUsers, username]);
      message.success(`Пользователь ${username} исключен из статистики`);
    }
  };

  const handleIncludeUser = (username) => {
    if (!excludedAccounts.includes(username)) {
      setExcludedUsers(excludedUsers.filter(u => u !== username));
      message.success(`Пользователь ${username} снова учитывается в статистике`);
    }
  };

  // Data Filtering
  const filteredData = useMemo(() => {
    let stats = [...data.statistics];
    let users = [...data.users];

    if (excludeSystemUsers || excludedUsers.length > 0) {
      const allExcluded = excludeSystemUsers ? excludedUsers : [];
      users = users.filter(user => !allExcluded.includes(`${user.username}`));
      stats = stats.filter(order => 
        !allExcluded.includes(
          users.find(u => u.id === order.userId)?.username
        )
      );
    }

    if (dateRange.length === 2) {
      stats = stats.filter(order => {
        const orderDate = new Date(order.date);
        return orderDate >= dateRange[0] && orderDate <= dateRange[1];
      });
    }

    if (region === 'usa') {
      stats = stats.filter(order => 
        order.region === 'USA' || 
        order.state.includes('usa-passport') || 
        order.state.includes('usa-ssn')
      );
    } else if (region === 'world') {
      stats = stats.filter(order => 
        order.region !== 'USA' && 
        !order.state.includes('usa-passport') && 
        !order.state.includes('usa-ssn')
      );
    }

    return { stats, users };
  }, [data, dateRange, region, excludeSystemUsers, excludedUsers]);

  // Table Columns
  const excludedUsersColumns = [
    {
      title: 'Пользователь',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => excludedAccounts.includes(record.username) ? 'Системный' : 'Пользовательский'
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_, record) => (
        !excludedAccounts.includes(record.username) && (
          <Button 
            type="link" 
            onClick={() => handleIncludeUser(record.username)}
          >
            Вернуть в статистику
          </Button>
        )
      )
    }
  ];

  const availableUsersColumns = [
    {
      title: 'Пользователь',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_, record) => (
        !excludedUsers.includes(record.username) && (
          <Button 
            type="link" 
            onClick={() => handleExcludeUser(record.username)}
          >
            Исключить из статистики
          </Button>
        )
      )
    }
  ];

  if (loading) return <Spin size="large" style={{ width: '100%', margin: '20px 0' }} />;

  return (
    <div style={{ padding: 24 }}>
      {/* Filters */}
<Card style={{ marginBottom: 24 }}>
  <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
    {/* Верхний ряд для широких экранов */}
    <div style={{ 
      display: 'flex', 
      flexWrap: 'wrap',
      gap: '16px'
    }}>
      <Select 
        style={{ 
          minWidth: '150px',
          flex: '0 0 auto'
        }}
        value={region}
        onChange={setRegion}
      >
        <Option value="all">Все регионы</Option>
        <Option value="usa">США</Option>
        <Option value="world">Мир</Option>
      </Select>

      <RangePicker 
        style={{ 
          flex: '1 1 auto',
          minWidth: '200px'
        }}
      />
    </div>

    {/* Нижний ряд для кнопок */}
    <div style={{ 
      display: 'flex', 
      flexWrap: 'wrap',
      gap: '16px',
      alignItems: 'center'
    }}>
      <Switch
        checked={excludeSystemUsers}
        onChange={setExcludeSystemUsers}
        checkedChildren="Системные исключены"
        unCheckedChildren="Системные включены"
        style={{ flexShrink: 0 }}
      />
      
      <Button 
        type="default"
        icon={<SettingOutlined />}
        onClick={() => setIsExcludeModalVisible(true)}
        style={{ 
          marginLeft: 'auto',
          whiteSpace: 'nowrap'
        }}
      >
        Исключенные
      </Button>
    </div>
  </div>
</Card>

      <DashboardCharts 
        region={region}
        filteredData={filteredData}
      />

      <Modal
        title="Управление исключенными пользователями"
        open={isExcludeModalVisible}
        onCancel={() => setIsExcludeModalVisible(false)}
        width={800}
        footer={null}
      >
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Search
              placeholder="Поиск пользователей"
              onChange={(e) => setSearchText(e.target.value)}
              style={{ marginBottom: 16 }}
            />
          </Col>
          <Col span={24}>
            <h3>Исключенные пользователи</h3>
            <Table
              dataSource={excludedUsers.map(username => ({ username }))}
              columns={excludedUsersColumns}
              rowKey="username"
              pagination={false}
              size="small"
            />
          </Col>
          <Col span={24}>
            <h3>Доступные пользователи</h3>
            <Table
              dataSource={data.users
                .filter(user => 
                  !excludedUsers.includes(`${user.username}`) &&
                  `${user.username}`.toLowerCase().includes(searchText.toLowerCase())
                )
                .map(user => ({ username: `${user.username}` }))}
              columns={availableUsersColumns}
              rowKey="username"
              pagination={false}
              size="small"
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default Dashboard;