import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button, ConfigProvider, theme } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { clearAdminPassword } from '../utils/auth';
import { 
  MenuOutlined, 
  UserOutlined,
  DashboardOutlined,
  TeamOutlined,
  WalletOutlined,
  DollarOutlined,
  WarningOutlined 
} from '@ant-design/icons';

const { Header, Content, Footer, Sider } = Layout;

const customTheme = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorPrimary: '#1668dc',
    colorBgBase: '#141414',
    colorBgContainer: '#1f1f1f',
    colorBgElevated: '#1f1f1f',
    colorText: '#ffffff',
    colorTextSecondary: '#a6a6a6',
    borderRadius: 4,
  },
  components: {
    Layout: {
      bodyBg: '#141414',
      headerBg: '#1f1f1f',
      siderBg: '#1f1f1f',
      triggerBg: '#262626',
    },
    Menu: {
      darkItemBg: '#1f1f1f',
      darkItemSelectedBg: '#262626',
      darkItemHoverBg: '#262626',
    },
    Card: {
      colorBgContainer: '#1f1f1f',
    },
    Button: {
      algorithm: true,
    },
  },
};

const MainLayout = ({ children }) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(window.innerWidth <= 768);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      if (mobile) {
        setCollapsed(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = () => {
    clearAdminPassword();
    navigate('/login');
  };

  const menuItems = [
    { 
      key: 'dashboard', 
      icon: <DashboardOutlined />,
      label: <Link to="/dashboard">Dashboard</Link> 
    },
    { 
      key: 'users', 
      icon: <TeamOutlined />,
      label: <Link to="/users">Users</Link> 
    },
    { 
      key: 'wallets', 
      icon: <WalletOutlined />,
      label: <Link to="/wallets">Wallets</Link> 
    },
    { 
      key: 'prices', 
      icon: <DollarOutlined />,
      label: <Link to="/prices">Prices</Link> 
    },
    { 
      key: 'errors', 
      icon: <WarningOutlined />,
      label: <Link to="/errors">Errors</Link> 
    },
  ];

  return (
    <ConfigProvider theme={customTheme}>
      <Layout style={{ minHeight: '100vh', background: '#141414' }}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          breakpoint="md"
          style={{
            position: isMobile ? 'fixed' : 'relative',
            height: '100vh',
            left: isMobile && collapsed ? -80 : 0,
            zIndex: 1001,
            background: '#1f1f1f',
            borderRight: '1px solid #434343',
            transition: 'all 0.2s'
          }}
          width={200}
        >
          <div 
            className="flex items-center justify-center cursor-pointer" 
            onClick={() => setCollapsed(!collapsed)}
            style={{
              height: '60px',
              borderBottom: '1px solid #434343',
              background: '#262626',
            }}
          >
            <div className="flex items-center gap-3">
              <UserOutlined style={{ fontSize: '20px', color: '#1668dc' }} />
              {!collapsed && (
                <span style={{ 
                  color: '#fff',
                  fontSize: '16px',
                  fontWeight: 500
                }}>
                  Admin Panel
                </span>
              )}
            </div>
          </div>

          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['dashboard']}
            items={menuItems}
            onClick={() => isMobile && setCollapsed(true)}
            style={{
              background: '#1f1f1f',
              borderRight: 0
            }}
          />
        </Sider>

        {isMobile && !collapsed && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.45)',
              backdropFilter: 'blur(4px)',
              zIndex: 1000,
            }}
            onClick={() => setCollapsed(true)}
          />
        )}

        <Layout style={{ background: '#141414' }}>
          <Header style={{
            padding: '0 16px',
            background: '#1f1f1f',
            borderBottom: '1px solid #434343',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '60px'
          }}>
            {isMobile && (
              <Button
                type="text"
                icon={<MenuOutlined />}
                onClick={() => setCollapsed(!collapsed)}
              />
            )}
            <div style={{ marginLeft: 'auto' }}>
              <Button onClick={handleLogout}>
                Logout
              </Button>
            </div>
          </Header>

          <Content style={{ 
            margin: '16px',
            minHeight: 'calc(100vh - 140px)',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div style={{
              flex: 1,
              padding: '16px',
              background: '#1f1f1f',
              borderRadius: '8px',
              border: '1px solid #434343',
            }}>
              {children}
            </div>
          </Content>

          <Footer style={{ 
            padding: '16px',
            textAlign: 'center',
            background: '#1f1f1f',
            borderTop: '1px solid #434343',
            height: '48px'
          }}>
            <div style={{ 
              fontSize: '13px',
              color: '#666',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px'
            }}>
              <span>Admin Panel</span>
              <span style={{ color: '#434343' }}>•</span>
              <span>©2025</span>
            </div>
          </Footer>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default MainLayout;