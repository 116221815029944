import React, { useEffect, useState } from 'react';
import { 
  Table, 
  Card, 
  Input, 
  message, 
  Typography,
  Tag,
  Button,
  Modal,
  Space
} from 'antd';
import { 
  SearchOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import { getErrors, getUsers } from '../services/api';
import { getAdminPassword } from '../utils/auth';

const { Text } = Typography;
const { TextArea } = Input;

const Errors = () => {
  const [errors, setErrors] = useState([]);
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [selectedError, setSelectedError] = useState(null);

  const loadUsers = async () => {
    try {
      const password = getAdminPassword();
      const response = await getUsers(password);
      if (response.status) {
        // Преобразуем массив пользователей в объект для быстрого доступа по ID
        const usersMap = response.users.reduce((acc, user) => {
          acc[user.id] = user;
          return acc;
        }, {});
        setUsers(usersMap);
      }
    } catch (error) {
      console.error('Error loading users:', error);
    }
  };

  const loadErrors = async () => {
    try {
      setLoading(true);
      const password = getAdminPassword();
      const response = await getErrors(password);
      
      if (response.status) {
        setErrors(response.orders);
      } else {
        message.error(response.errMsg || 'Ошибка загрузки данных');
      }
    } catch (error) {
      console.error('Error loading errors:', error);
      message.error('Ошибка при загрузке данных');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await loadUsers();
      await loadErrors();
    };
    loadData();
  }, []);

  const showErrorDetails = (error) => {
    setSelectedError(error);
    setDetailsModalVisible(true);
  };

  const decodeBase64Data = (base64) => {
    try {
      const decodedString = atob(base64);
      const data = JSON.parse(decodedString);
      return data;
    } catch (error) {
      console.error('Error decoding base64:', error);
      return null;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const filteredErrors = errors.filter(error => {
    const searchLower = searchText.toLowerCase();
    const user = users[error.userId];
    return (
      error.userId.toLowerCase().includes(searchLower) ||
      (user?.username || '').toLowerCase().includes(searchLower) ||
      error.state.toLowerCase().includes(searchLower)
    );
  });

  const columns = [
    {
      title: 'ИНФОРМАЦИЯ',
      key: 'info',
      render: (record) => {
        const user = users[record.userId];
        return (
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <CalendarOutlined style={{ color: '#8c8c8c' }} />
              <Text style={{ fontSize: '14px' }}>{formatDate(record.created)}</Text>
            </div>
            
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Space>
                <UserOutlined style={{ color: '#1890ff' }} />
                <Text strong>{user?.username || 'Неизвестный пользователь'}</Text>
              </Space>
              <Text type="secondary" style={{ fontSize: '13px' }}>ID: {record.userId}</Text>
            </div>
          </Space>
        );
      }
    },
    {
      title: 'РЕГИОН',
      dataIndex: 'state',
      key: 'state',
      width: 200,
      render: (state) => (
        <Tag color="cyan" style={{ fontSize: '14px', padding: '4px 8px' }}>
          {state}
        </Tag>
      )
    },
    {
      title: 'ТОВАР',
      key: 'product',
      render: (record) => {
        const orderData = decodeBase64Data(record.base64Data);
        return orderData ? (
          <Space direction="vertical" size="small">
            <Text strong style={{ fontSize: '14px' }}>{orderData.reg || 'Нет данных'}</Text>
            <Text type="secondary" style={{ fontSize: '13px' }}>
              Цена: ${orderData.price || 'N/A'}
            </Text>
          </Space>
        ) : (
          <Text type="secondary">Ошибка данных</Text>
        );
      }
    },
    {
      title: 'ДЕЙСТВИЯ',
      key: 'actions',
      width: 100,
      align: 'right',
      render: (_, record) => (
        <Button
          danger
          type="primary"
          icon={<ExclamationCircleOutlined />}
          onClick={() => showErrorDetails(record)}
          size="small"
        >
          Ошибка
        </Button>
      ),
    }
  ];

  return (
    <div style={{ padding: '12px' }}>
      <Card bodyStyle={{ padding: '12px' }} bordered={false}>
        <Input
          placeholder="Поиск по ID или имени пользователя"
          prefix={<SearchOutlined style={{ color: '#1890ff' }} />}
          allowClear
          onChange={e => setSearchText(e.target.value)}
          style={{ marginBottom: 16, maxWidth: 400 }}
        />

        <Table
          dataSource={filteredErrors}
          columns={columns}
          rowKey="userId"
          loading={loading}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
            showTotal: (total) => `Всего ${total} ошибок`
          }}
          size="middle"
        />
      </Card>

      <Modal
        title={
          <Space>
            <ExclamationCircleOutlined style={{ color: '#ff4d4f', fontSize: '20px' }} />
            <Text strong>Информация об ошибке</Text>
          </Space>
        }
        open={detailsModalVisible}
        onCancel={() => {
          setDetailsModalVisible(false);
          setSelectedError(null);
        }}
        footer={null}
        width={600}
      >
        {selectedError && (
          <div style={{ marginTop: 16 }}>
            <Card 
              size="small" 
              title="Информация о пользователе" 
              style={{ marginBottom: 16 }}
            >
              {(() => {
                const user = users[selectedError.userId];
                return (
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <div>
                      <Text type="secondary">Пользователь: </Text>
                      <Text strong>{user?.username || 'Неизвестный пользователь'}</Text>
                    </div>
                    <div>
                      <Text type="secondary">ID: </Text>
                      <Text>{selectedError.userId}</Text>
                    </div>
                  </Space>
                );
              })()}
            </Card>

            <Card 
              size="small" 
              title="Данные заказа" 
              style={{ marginBottom: 16 }}
            >
              {(() => {
                const orderData = decodeBase64Data(selectedError.base64Data);
                return orderData ? (
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <div>
                      <Text type="secondary">Регион: </Text>
                      <Tag color="cyan">{selectedError.state}</Tag>
                    </div>
                    {orderData.price && (
                      <div>
                        <Text type="secondary">Цена: </Text>
                        <Text strong>${orderData.price}</Text>
                      </div>
                    )}
                  </Space>
                ) : (
                  <Text type="secondary">Ошибка чтения данных</Text>
                );
              })()}
            </Card>

            <Card 
              size="small" 
              title="Текст ошибки" 
              headStyle={{ color: '#ff4d4f' }}
            >
              <TextArea
                value={selectedError.errorMsg}
                autoSize={{ minRows: 3, maxRows: 6 }}
                style={{ 
                  marginTop: 8,
                  color: '#ff4d4f',
                  fontFamily: 'monospace',
                  fontSize: '13px'
                }}
                readOnly
              />
            </Card>
          </div>
        )}
      </Modal>

      <style jsx global>{`
        .error-table .ant-table {
          background: transparent;
        }
        .error-table .ant-table-cell {
          background: transparent !important;
          border-bottom: 1px solid #434343 !important;
        }
        .error-table .ant-table-row:hover .ant-table-cell {
          background: #262626 !important;
        }
        @media (max-width: 768px) {
          .ant-table-content {
            overflow-x: auto;
          }
          .ant-table-row > td {
            padding: 12px 8px !important;
          }
          .ant-modal {
            max-width: 100% !important;
            margin: 0 !important;
          }
        }
      `}</style>
    </div>
  );
};

export default Errors;