import React, { useEffect, useState } from 'react';
import { 
  Table, 
  Button, 
  Modal, 
  Input, 
  message, 
  Space,
  Card,
  Typography,
  Tag
} from 'antd';
import { 
  PlusOutlined, 
  DeleteOutlined, 
  CopyOutlined,
  SearchOutlined,
  EditOutlined,
  EyeOutlined,
  EyeInvisibleOutlined
} from '@ant-design/icons';
import { getWallets } from '../services/api';
import { getAdminPassword } from '../utils/auth';

const { Text } = Typography;

const Wallets = () => {
  const [wallets, setWallets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editingWallet, setEditingWallet] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [hiddenSeeds, setHiddenSeeds] = useState(new Set());
  const [newWalletData, setNewWalletData] = useState({
    name: '',
    seed: ''
  });

  // Загрузка данных
  const loadWallets = async () => {
    try {
      setLoading(true);
      const password = getAdminPassword();
      const response = await getWallets(password);
      console.log(response)
      
      if (response.status) {
        const walletsWithNames = response.wallets.map(wallet => ({
          ...wallet,
          name: wallet.name || 'Кошелек ' + wallet.id.slice(-4)
        }));
        setWallets(walletsWithNames);
      } else {
        message.error(response.errMsg || 'Ошибка загрузки данных');
      }
    } catch (error) {
      message.error('Ошибка при загрузке данных');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadWallets();
  }, []);

  // Копирование в буфер
  const copyToClipboard = (text, name) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success(`Seed-фраза ${name} скопирована`);
    }).catch(() => {
      message.error('Ошибка при копировании');
    });
  };

  // Переключение видимости seed-фразы
  const toggleSeedVisibility = (id) => {
    const newHidden = new Set(hiddenSeeds);
    if (newHidden.has(id)) {
      newHidden.delete(id);
    } else {
      newHidden.add(id);
    }
    setHiddenSeeds(newHidden);
  };

  // Добавление нового кошелька
  const handleAdd = async () => {
    if (!newWalletData.seed.trim()) {
      message.error('Введите seed-фразу');
      return;
    }

    try {
      const newWallet = {
        id: Date.now().toString(),
        seed: newWalletData.seed.trim(),
        name: newWalletData.name.trim() || 'Кошелек ' + Date.now().toString().slice(-4)
      };

      setWallets(prev => [...prev, newWallet]);
      setNewWalletData({ name: '', seed: '' });
      setIsModalVisible(false);
      message.success('Кошелек добавлен');
    } catch (error) {
      message.error('Ошибка при добавлении');
    }
  };

  // Функционал редактирования
  const handleEdit = async () => {
    if (!editingWallet) return;

    try {
      setWallets(prev => prev.map(wallet => 
        wallet.id === editingWallet.id ? editingWallet : wallet
      ));
      setEditModalVisible(false);
      setEditingWallet(null);
      message.success('Кошелек обновлен');
    } catch (error) {
      message.error('Ошибка при обновлении');
    }
  };

  // Удаление кошелька
  const handleDelete = async (id, name) => {
    try {
      setWallets(prev => prev.filter(wallet => wallet.id !== id));
      message.success(`Кошелек ${name} удален`);
    } catch (error) {
      message.error('Ошибка при удалении');
    }
  };

  const startEdit = (wallet) => {
    setEditingWallet(wallet);
    setEditModalVisible(true);
  };

  const filteredWallets = wallets.filter(wallet => 
    wallet.name.toLowerCase().includes(searchText.toLowerCase()) ||
    wallet.seed.toLowerCase().includes(searchText.toLowerCase())
  );

  // Форматирование seed-фразы для отображения
  const formatSeed = (seed) => {
    return seed.split(' ').map((word, index) => (
        <Tag 
          key={index} 
          style={{ 
            margin: '2px',
            background: '#262626',
            color: '#ffffff',
            border: '1px solid #434343',
            borderRadius: '4px',
            padding: '4px 8px',
            fontSize: '14px'
        }}
      >
        {word}
      </Tag>
    ));
  };

  const columns = [
    {
      title: 'КОШЕЛЕК',
      key: 'wallet',
      render: (record) => (
        <div style={{ fontSize: '14px' }}>
          <div style={{ 
            fontWeight: 500, 
            marginBottom: 8,
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}>
            <Text style={{ 
              fontSize: '16px',
              color: '#ffffff'
            }}>
              {record.name}
            </Text>
          </div>
          <div style={{ 
            wordBreak: 'break-word',
            lineHeight: '28px',
            display: hiddenSeeds.has(record.id) ? 'none' : 'block'
          }}>
            {formatSeed(record.seed)}
          </div>
        </div>
      )
    },
    {
        title: 'ДЕЙСТВИЯ',
        key: 'actions',
        width: 140,
        align: 'right',
        render: (_, record) => (
          <Space size="middle">
            <Button
              type="text"
              icon={<EyeOutlined style={{ color: '#1668dc' }} />}
              onClick={() => toggleSeedVisibility(record.id)}
              style={{ color: '#1668dc' }}
            />
            <Button
              type="text"
              icon={<EditOutlined style={{ color: '#1668dc' }} />}
              onClick={() => startEdit(record)}
              style={{ color: '#1668dc' }}
            />
            <Button
              type="text"
              icon={<CopyOutlined style={{ color: '#1668dc' }} />}
              onClick={() => copyToClipboard(record.seed, record.name)}
              style={{ color: '#1668dc' }}
            />
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(record.id, record.name)}
            />
          </Space>
      ),
    },
  ];

  return (
    <div style={{ 
      padding: '12px', 
      display: 'flex', 
      flexDirection: 'column', 
      height: '100vh'
    }}>
      <Card
        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
        bodyStyle={{ 
          padding: '12px',
          flex: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
        bordered={false}
      >
        <div style={{ marginBottom: 16 }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsModalVisible(true)}
            style={{ width: '100%', height: 40 }}
          >
            Добавить кошелек
          </Button>
        </div>

        <Input
          placeholder="Поиск по названию или фразе"
          prefix={<SearchOutlined style={{ color: '#1890ff' }} />}
          allowClear
          onChange={e => setSearchText(e.target.value)}
          style={{ marginBottom: 16 }}
        />

        <Table
          dataSource={filteredWallets}
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
            showQuickJumper: false,
            showTotal: (total) => `Всего ${total} кошельков`
          }}
          style={{ flex: 1 }}
          size="middle"
        />
      </Card>

      {/* Модальное окно добавления */}
      <Modal
        title="Добавить кошелек"
        open={isModalVisible}
        onOk={handleAdd}
        onCancel={() => {
          setIsModalVisible(false);
          setNewWalletData({ name: '', seed: '' });
        }}
        okText="Добавить"
        cancelText="Отмена"
      >
        <Input
          placeholder="Название кошелька"
          value={newWalletData.name}
          onChange={e => setNewWalletData(prev => ({ ...prev, name: e.target.value }))}
          style={{ marginBottom: 16, marginTop: 16 }}
        />
        <Input.TextArea
          placeholder="Введите seed-фразу"
          value={newWalletData.seed}
          onChange={e => setNewWalletData(prev => ({ ...prev, seed: e.target.value }))}
          rows={4}
          style={{ fontFamily: 'monospace' }}
        />
      </Modal>

      {/* Модальное окно редактирования */}
      <Modal
        title="Редактировать кошелек"
        open={editModalVisible}
        onOk={handleEdit}
        onCancel={() => {
          setEditModalVisible(false);
          setEditingWallet(null);
        }}
        okText="Сохранить"
        cancelText="Отмена"
      >
        <Input
          placeholder="Название кошелька"
          value={editingWallet?.name}
          onChange={e => setEditingWallet(prev => ({ ...prev, name: e.target.value }))}
          style={{ marginBottom: 16, marginTop: 16 }}
        />
        <Input.TextArea
          placeholder="Seed-фраза"
          value={editingWallet?.seed}
          onChange={e => setEditingWallet(prev => ({ ...prev, seed: e.target.value }))}
          rows={4}
          style={{ fontFamily: 'monospace' }}
        />
      </Modal>

      <style>{`
        .ant-table-thead > tr > th {
          background: transparent !important;
          border-bottom: none !important;
          padding: 8px 16px !important;
          font-size: 12px !important;
        }
        
        .ant-table-tbody > tr > td {
          border-bottom: 1px solid #f0f0f0 !important;
          padding: 12px 16px !important;
        }

        .ant-table-tbody > tr:last-child > td {
          border-bottom: none !important;
        }

        .ant-pagination {
          margin: 16px 0 0 0 !important;
          display: flex;
          justify-content: center;
        }

        @media (max-width: 768px) {
          .ant-table {
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  );
};

export default Wallets;