// src/services/api.js

// API
import { server } from "../config/host";

export const getUsers = async (password) => {
  try {
    const response = await fetch(`${server}/api/getUsers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //   'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({
        password,
      }),
    });
    return await response.json();
  } catch (e) {
    console.log(e)
    return {status: false, errMsg: e.message}
  }
}

export const getUser = async (password, id) => {
  try {
    const response = await fetch(`${server}/api/getUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //   'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({
        password,
        id,
      }),
    });
    return await response.json();
  } catch (e) {
    console.log(e)
    return {status: false, errMsg: e.message}
  }
}

export const setUserDeactivate = async (password, id, deactivate) => {
  try {
    const response = await fetch(`${server}/api/setUserDeactivate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
        id,
        deactivate,
      }),
    });
    return await response.json();
  } catch (e) {
    console.log(e)
    return {status: false, errMsg: e.message}
  }
}
export const setUserCoin = async (password, id, coin) => {
  try {
    const response = await fetch(`${server}/api/setUserCoin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
        id,
        coin,
      }),
    });
    return await response.json();
  } catch (e) {
    console.log(e)
    return {status: false, errMsg: e.message}
  }
}
export const getOrder = async (password, idOrder) => {
  try {
    const response = await fetch(`${server}/api/getOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
        idOrder,
      }),
    });
    return await response.json();
  } catch (e) {
    console.log(e)
    return {status: false, errMsg: e.message}
  }
}
export const getWallets = async (password) => {
  try {
    const response = await fetch(`${server}/api/getWallets`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
      }),
    });
    return await response.json();
  } catch (e) {
    console.log(e)
    return {status: false, errMsg: e.message}
  }
}
export const addWallet = async (password, seed) => {
  try {
    const response = await fetch(`${server}/api/addWallet`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
        seed,
      }),
    });
    return await response.json();
  } catch (e) {
    console.log(e)
    return {status: false, errMsg: e.message}
  }
}
export const deleteWallet = async (password, id) => {
  try {
    const response = await fetch(`${server}/api/deleteWallet`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
        id,
      }),
    });
    return await response.json();
  } catch (e) {
    console.log(e)
    return {status: false, errMsg: e.message}
  }
}

export const getPrices = async (password) => {
  try {
    const response = await fetch(`${server}/api/getPrices`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //   'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({
        password,
      }),
    });
    return await response.json();
  } catch (e) {
    console.log(e)
    return {status: false, errMsg: e.message}
  }
}
export const setPrice = async (password, id, value) => {
  try {
    const response = await fetch(`${server}/api/setPrice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
        id,
        value,
      }),
    });
    return await response.json();
  } catch (e) {
    console.log(e)
    return {status: false, errMsg: e.message}
  }
}
export const getStatistics = async (password) => {
  try {
    const response = await fetch(`${server}/api/getStatistics`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
      }),
    });
    return await response.json();
  } catch (e) {
    console.log(e)
    return {status: false, errMsg: e.message}
  }
}
export const getErrors = async (password, limit = 20) => {
  try {
    const response = await fetch(`${server}/api/getErrors`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
        limit,
      }),
    });
    return await response.json();
  } catch (e) {
    console.log(e)
    return {status: false, errMsg: e.message}
  }
}

// Экспорт всех методов API
export default {
  getWallets,
  deleteWallet,
  addWallet,
  getUsers,
  setUserDeactivate,
  setUserCoin,
  getStatistics,
  getPrices,
  getErrors,
  getOrder
};