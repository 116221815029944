import React from 'react';
import { Card, Row, Col, Statistic } from 'antd';
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer,
  CartesianGrid, Legend, PieChart, Pie, Cell
} from 'recharts';
import {
  DollarOutlined, UserOutlined, ShoppingCartOutlined,
  TeamOutlined, FileOutlined, IdcardOutlined, BankOutlined
} from '@ant-design/icons';
import { useMetrics, useGeneralStats, useUsaMetrics } from './DashboardMetrics';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

const cardStyle = {
  background: '#1f1f1f',
  border: '1px solid rgba(255,255,255,0.1)'
};

const titleStyle = {
  color: '#fff',
  borderBottom: '1px solid rgba(255,255,255,0.1)'
};

const statisticStyle = {
  title: { color: '#a6a6a6' },
  value: { color: '#fff' }
};

export const DashboardCharts = ({ region, filteredData }) => {
  const metrics = useMetrics(filteredData);
  const generalStats = useGeneralStats(filteredData);
  const usaMetrics = useUsaMetrics(filteredData);

  const renderMainStats = () => (
    <Row gutter={[24, 24]}>
      <Col xs={24} sm={12} lg={6}>
        <Card style={cardStyle}>
          <Statistic
            title={<span style={statisticStyle.title}>Всего заказов</span>}
            value={metrics.totalOrders}
            valueStyle={statisticStyle.value}
            prefix={<ShoppingCartOutlined />}
          />
        </Card>
      </Col>
      <Col xs={24} sm={12} lg={6}>
        <Card style={cardStyle}>
          <Statistic
            title={<span style={statisticStyle.title}>Заказов в месяц</span>}
            value={metrics.monthlyOrders}
            valueStyle={statisticStyle.value}
            prefix={<ShoppingCartOutlined />}
          />
        </Card>
      </Col>
      <Col xs={24} sm={12} lg={6}>
        <Card style={cardStyle}>
          <Statistic
            title={<span style={statisticStyle.title}>Общая выручка</span>}
            value={metrics.totalRevenue}
            valueStyle={statisticStyle.value}
            prefix={<DollarOutlined />}
            precision={2}
          />
        </Card>
      </Col>
      <Col xs={24} sm={12} lg={6}>
        <Card style={cardStyle}>
          <Statistic
            title={<span style={statisticStyle.title}>Выручка в месяц</span>}
            value={metrics.monthlyRevenue}
            valueStyle={statisticStyle.value}
            prefix={<DollarOutlined />}
            precision={2}
          />
        </Card>
      </Col>
      <Col xs={24} sm={12} lg={6}>
        <Card style={cardStyle}>
          <Statistic
            title={<span style={statisticStyle.title}>Всего пользователей</span>}
            value={metrics.totalUsers}
            valueStyle={statisticStyle.value}
            prefix={<TeamOutlined />}
          />
        </Card>
      </Col>
      <Col xs={24} sm={12} lg={6}>
        <Card style={cardStyle}>
          <Statistic
            title={<span style={statisticStyle.title}>Активных пользователей</span>}
            value={metrics.activeUsers}
            valueStyle={statisticStyle.value}
            prefix={<UserOutlined />}
          />
        </Card>
      </Col>
    </Row>
  );

  const renderAllRegionsContent = () => (
    <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
      <Col xs={24} lg={12}>
        <Card title="Топ 10 товаров" style={cardStyle} headStyle={titleStyle}>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={generalStats.topProducts}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="45%"
                outerRadius="75%"
                labelLine={false} // Убираем линии к подписям
                label={({ name, percent }) => {
                  // Сокращаем длинные названия
                  const shortName = name.length > 15 ? name.slice(0, 15) + '...' : name;
                  return `${(percent * 100).toFixed(0)}%`;
                }}
              >
                {generalStats.topProducts.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip 
                contentStyle={{ background: '#262626', border: 'none' }}
                itemStyle={{ color: '#fff' }}
                formatter={(value, name) => [`${value} (${((value / generalStats.topProducts.reduce((a, b) => a + b.value, 0)) * 100).toFixed(1)}%)`, name]}
              />
              <Legend 
                formatter={(value) => <span style={{ color: '#fff' }}>{value}</span>}
                layout="horizontal"
                align="center"
                verticalAlign="bottom"
                wrapperStyle={{
                  paddingTop: '20px',
                  fontSize: '12px'
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </Card>
      </Col>
      <Col xs={24} lg={12}>
        <Card title="Распределение по регионам" style={cardStyle} headStyle={titleStyle}>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={generalStats.regionDistribution}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="45%"
                outerRadius="75%"
                label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                labelLine={{ stroke: '#fff', strokeWidth: 0.5 }}
              >
                {generalStats.regionDistribution.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip 
                contentStyle={{ background: '#262626', border: 'none' }}
                itemStyle={{ color: '#fff' }}
                formatter={(value, name) => [`${value} (${((value / generalStats.regionDistribution.reduce((a, b) => a + b.value, 0)) * 100).toFixed(1)}%)`, name]}
              />
              <Legend 
                formatter={(value) => <span style={{ color: '#fff' }}>{value}</span>}
                layout="horizontal"
                align="center"
                verticalAlign="bottom"
              />
            </PieChart>
          </ResponsiveContainer>
        </Card>
      </Col>
    </Row>
  );

  const renderUsaStats = () => (
    <>
      <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
        <Col xs={24} md={8}>
          <Card style={cardStyle}>
            <Statistic
              title={<span style={statisticStyle.title}>US Passport</span>}
              value={usaMetrics.docs.passport.count}
              valueStyle={statisticStyle.value}
              prefix={<FileOutlined />}
              suffix={` / $${usaMetrics.docs.passport.revenue.toFixed(2)}`}
            />
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card style={cardStyle}>
            <Statistic
              title={<span style={statisticStyle.title}>SSN</span>}
              value={usaMetrics.docs.ssn.count}
              valueStyle={statisticStyle.value}
              prefix={<IdcardOutlined />}
              suffix={` / $${usaMetrics.docs.ssn.revenue.toFixed(2)}`}
            />
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card style={cardStyle}>
            <Statistic
              title={<span style={statisticStyle.title}>Штаты</span>}
              value={usaMetrics.docs.states.count}
              valueStyle={statisticStyle.value}
              prefix={<BankOutlined />}
              suffix={` / $${usaMetrics.docs.states.revenue.toFixed(2)}`}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
        <Col xs={24} lg={12}>
          <Card title="Топ штатов по количеству заказов" style={cardStyle} headStyle={titleStyle}>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={usaMetrics.statesTop} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
                <XAxis type="number" stroke="#a6a6a6" />
                <YAxis 
                  dataKey="name" 
                  type="category" 
                  width={120} 
                  stroke="#a6a6a6"
                  tick={{ fill: '#fff' }}
                />
                <Tooltip 
                  contentStyle={{ background: '#262626', border: 'none' }}
                  itemStyle={{ color: '#fff' }}
                />
                <Bar dataKey="value" name="Заказы" fill="#82ca9d" radius={[0, 4, 4, 0]} />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>

        <Col xs={24} lg={12}>
        <Card title="Распределение типов документов" style={cardStyle} headStyle={titleStyle}>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={[
                  { name: 'Паспорта', value: usaMetrics.docs.passport.count },
                  { name: 'SSN', value: usaMetrics.docs.ssn.count },
                  { name: 'Штаты', value: usaMetrics.docs.states.count }
                ]}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="45%"
                outerRadius="75%"
                label={({ name, percent }) => `${(percent * 100).toFixed(0)}%`}
                labelLine={false}
              >
                {COLORS.map((color, index) => (
                  <Cell key={`cell-${index}`} fill={color} />
                ))}
              </Pie>
              <Tooltip 
                contentStyle={{ background: '#262626', border: 'none' }}
                itemStyle={{ color: '#fff' }}
                formatter={(value, name) => [
                  `${value} (${((value / Object.values(usaMetrics.docs).reduce((a, b) => a + b.count, 0)) * 100).toFixed(1)}%)`,
                  name
                ]}
              />
              <Legend 
                formatter={(value) => <span style={{ color: '#fff' }}>{value}</span>}
                layout="horizontal"
                align="center"
                verticalAlign="bottom"
                wrapperStyle={{ paddingTop: '20px' }}
              />
            </PieChart>
          </ResponsiveContainer>
        </Card>
      </Col>
      </Row>

      <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
        <Col xs={24}>
        <Card title="Выручка по типам документов" style={cardStyle} headStyle={titleStyle}>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={[
                { name: 'Паспорта', value: usaMetrics.docs.passport.revenue },
                { name: 'SSN', value: usaMetrics.docs.ssn.revenue },
                { name: 'Штаты', value: usaMetrics.docs.states.revenue }
              ]}
              margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
              <XAxis 
                dataKey="name" 
                stroke="#a6a6a6" 
                tick={{ fill: '#fff' }}
                interval={0}
                angle={0}
                textAnchor="middle"
              />
              <YAxis 
                stroke="#a6a6a6" 
                tick={{ fill: '#fff' }}
                tickFormatter={(value) => `$${value.toFixed(0)}`}
              />
              <Tooltip 
                formatter={(value) => `$${value.toFixed(2)}`}
                contentStyle={{ background: '#262626', border: 'none' }}
                itemStyle={{ color: '#fff' }}
                cursor={{ fill: 'rgba(255,255,255,0.1)' }}
              />
              <Bar 
                dataKey="value" 
                name="Выручка" 
                fill="#8884d8" 
                radius={[4, 4, 0, 0]}
                maxBarSize={60}
              />
            </BarChart>
          </ResponsiveContainer>
        </Card>
        </Col>
      </Row>
    </>
  );

  const renderWorldStats = () => (
    <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
      <Col xs={24}>
        <Card title="Топ стран по количеству заказов" style={cardStyle} headStyle={titleStyle}>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={generalStats.worldStats} layout="vertical">
              <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
              <XAxis type="number" stroke="#a6a6a6" />
              <YAxis 
                dataKey="name" 
                type="category" 
                width={120} 
                stroke="#a6a6a6"
                tick={{ fill: '#fff' }}
              />
              <Tooltip 
                contentStyle={{ background: '#262626', border: 'none' }}
                itemStyle={{ color: '#fff' }}
              />
              <Legend formatter={(value) => <span style={{ color: '#fff' }}>{value}</span>} />
              <Bar dataKey="value" name="Заказы" fill="#8884d8" radius={[0, 4, 4, 0]} />
            </BarChart>
          </ResponsiveContainer>
        </Card>
      </Col>
    </Row>
  );

  return (
    <div className="p-4">
      {renderMainStats()}
      {region === 'all' && renderAllRegionsContent()}
      {region === 'usa' && renderUsaStats()}
      {region === 'world' && renderWorldStats()}
    </div>
  );
};

export default DashboardCharts;