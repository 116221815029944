import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Input, Form, message } from 'antd';
import { setAdminPassword } from '../utils/auth';
import { getUser } from '../services/api';
import { excludedAccounts } from '../config/excludedAccounts';

const Login = () => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const onFinish = async () => {
    const res = await getUser(password, '1056060544');
    if(res.status){
      setAdminPassword(password);
      navigate('/dashboard');
    } else {
      message.error('Неверный пароль!');
    }      
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: '#141414'
      }}
    >
      <Card 
        title="Admin Control Panel"
        style={{ 
          width: 300,
          background: '#1f1f1f',
          border: '1px solid #434343',
        }}
        headStyle={{
          color: '#fff',
          fontSize: '18px',
          textAlign: 'center',
          borderBottom: '1px solid #434343',
          padding: '16px'
        }}
        bodyStyle={{
          padding: '24px'
        }}
      >
        <Form onFinish={onFinish}>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Пожалуйста, введите пароль' }]}
          >
            <Input.Password 
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Введите пароль"
              size="large"
              style={{
                background: '#808080',
                borderColor: '#808080',
                color: '#808080'
              }}
              className="hover:border-blue-500 focus:border-blue-500"
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button 
              type="primary"
              htmlType="submit"
              size="large"
              block
              style={{
                height: '40px',
                background: '#1668dc',
                borderColor: '#1668dc'
              }}
              className="hover:bg-blue-600 hover:border-blue-600"
            >
              Войти
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <style jsx global>{`
        .ant-input-affix-wrapper {
          background: #141414 !important;
          border-color: #434343 !important;
          box-shadow: none !important;
        }
        .ant-input-affix-wrapper:hover {
          border-color: #1668dc !important;
        }
        .ant-input-affix-wrapper-focused {
          border-color: #1668dc !important;
          box-shadow: none !important;
        }
        .ant-input {
          background: transparent !important;
          color: white !important;
        }
        .ant-input-password-icon {
          color: #666 !important;
        }
        .ant-input-password-icon:hover {
          color: #999 !important;
        }
        .ant-form-item-explain-error {
          color: #ff4d4f !important;
        }
        .ant-message-notice-content {
          background: #1f1f1f !important;
          border: 1px solid #434343 !important;
          color: white !important;
        }
        .ant-message-error {
          color: #ff4d4f !important;
        }
      `}</style>
    </div>
  );
};

export default Login;