import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import MainLayout from './components/MainLayout';
import Dashboard from './components/Dashboard';
import Users from './components/Users';
import Wallets from './components/Wallets'; // Импортируем реальный компонент
import Prices from './components/Prices';
import { isAuthenticated } from './utils/auth';
import Errors from './components/Errors';

// Временные компоненты для оставшихся страниц
// const Prices = () => <h1>Prices Page</h1>;
//const Errors = () => <h1>Errors Page</h1>;

const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }
  return <MainLayout>{children}</MainLayout>;
};

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
      <Route path="/users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
      <Route path="/wallets" element={<ProtectedRoute><Wallets /></ProtectedRoute>} />
      <Route path="/prices" element={<ProtectedRoute><Prices /></ProtectedRoute>} />
      <Route path="/errors" element={<ProtectedRoute><Errors /></ProtectedRoute>} />
      <Route path="/" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
};

export default App;