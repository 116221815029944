// src/utils/auth.js
const PASSWORD_KEY = 'adminPassword';

export const setAdminPassword = (password) => {
  localStorage.setItem(PASSWORD_KEY, password.trim());
};

export const getAdminPassword = () => {
  return (localStorage.getItem(PASSWORD_KEY) || '').trim();
};

export const isAuthenticated = () => !!getAdminPassword();

export const clearAdminPassword = () => {
  localStorage.removeItem(PASSWORD_KEY);
};
